import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"
// Learn more: https://www.framer.com/docs/guides/overrides/

const useStore = createStore({
    background: "#0099FF",
})

const LINES = 3
export default function withLineTruncate(Component): ComponentType {
    return (props) => {
        const styles = {
            ...props?.style,
            WebkitLineClamp: `${LINES}`,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            overflow: "hidden",
        }
        return (
            <>
                <Component {...props} style={styles} />
            </>
        )
    }
}
